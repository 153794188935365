import { MouseEventHandler, ReactNode, RefObject, UIEventHandler } from 'react';
import styled from 'styled-components/macro';
import CardGrid, { CardGridItem, CardGridItemLink } from './CardGrid';
import Scrollable from './Scrollable';
import { outlineCardCss } from './outlineCss';

export function CustomizationGridContainer({
  children,
  maskSize,
  header,
  innerRef,
  onScroll,
  className,
}: {
  className?: string;
  header?: ReactNode;
  children: ReactNode;
  maskSize?: number;
  innerRef?: RefObject<HTMLDivElement>;
  onScroll?: UIEventHandler<HTMLDivElement>;
}) {
  return (
    <CustomizationGridContainerRoot className={className}>
      {header}
      <CustomizationGridContainerInner
        ref={innerRef}
        maskSize={maskSize}
        onScroll={onScroll}
      >
        {children}
      </CustomizationGridContainerInner>
    </CustomizationGridContainerRoot>
  );
}

export const CustomizationGridContainerRoot = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  width: 100%;
  background: rgba(0 0 0 / 8%);
  backdrop-filter: blur(25px);
  border-radius: 24px;
  max-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const CustomizationGridContainerInner = styled(Scrollable)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  scrollbar-gutter: stable both-edges;
  width: 100%;
  height: 100%;
  min-height: 0;
`;

export const CustomizationGrid = styled(CardGrid)`
  margin: 0;
  padding: 10px 10px;
  @media ${(p) => p.theme.breakpoints.tablet} {
    min-width: 420px;
    padding: 15px 8px;
  }
`;

const CustomizationGridItemRoot = styled(CardGridItem)<{ $checked: boolean }>`
  ${(p) => p.$checked && outlineCardCss({ shadow: '8px', offset: '-2px' })};

  &:focus-within {
    ${(p) => p.$checked && outlineCardCss({ shadow: '8px', offset: '-2px' })};
  }

  &:has(:focus-visible) {
    ${(p) =>
      p.$checked
        ? outlineCardCss({
            shadow: '12px',
            outlineOpacity: '90%',
            offset: '-1px',
          })
        : outlineCardCss({
            shadow: '0px',
            outlineOpacity: '50%',
            offset: '-1px',
          })};
  }
`;

const ItemName = styled.p`
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  font-family: ${(p) => p.theme.fonts.display};

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`;

type ItemProps = {
  checked: boolean;
  size?: number;
  backgroundImageUrl?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  name?: string;
  className?: string;
  children?: React.ReactNode;
  itemId?: string;
  onScrollSelect?: () => void;
};

export function CustomizationGridItem({
  checked,
  size,
  backgroundImageUrl,
  onClick,
  name,
  className,
  children,
  itemId,
  onScrollSelect,
}: ItemProps) {
  return (
    <CustomizationGridItemRoot
      $checked={checked}
      size={size}
      backgroundImageUrl={backgroundImageUrl}
      className={className}
      itemId={itemId}
      onScrollSelect={onScrollSelect}
    >
      <CardGridItemLink
        tabIndex={checked ? 0 : -1}
        onClick={(e) => {
          e.preventDefault();
          onClick?.(e);
        }}
      >
        {name && <ItemName>{name}</ItemName>}
        {children}
      </CardGridItemLink>
    </CustomizationGridItemRoot>
  );
}
