import useMergedRef from '@react-hook/merged-ref';
import * as React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as TrashIcon } from '../icons/Trash.svg';
import useFocusGroup from '../utils/useFocusGroup';
import useFocusVisibleWithin from '../utils/useFocusVisibleWithin';
import LegacyAriaButton from './legacy/AriaButton';
import LegacyIconButton from './legacy/IconButton';

import { IconButton } from './Buttons';
import { useScrollToStoreItem } from './StoreCategoryGrid/hooks/useScrollToStoreItem';

type ItemProps = {
  itemId?: string;
  className?: string;
  backgroundImageUrl?: string | { jpg: string; webp: string };
  standalone?: boolean;
  children?: React.ReactNode;
  'data-testid'?: string;
  size?: number;
  stretchContent?: boolean;
  onRemove?: () => void;
  onScrollSelect?: (variationId?: string) => void;
  noFocusWithin?: boolean;
  id?: string;
  disabled?: boolean;
  legacy?: boolean;
};

export function CardGridItem({
  id,
  itemId,
  className,
  backgroundImageUrl,
  standalone,
  children,
  size = 140,
  'data-testid': dataTestId,
  stretchContent,
  onRemove,
  onScrollSelect,
  noFocusWithin,
  disabled,
  legacy = false,
}: ItemProps) {
  const [ref, focusVisibleWithin] =
    useFocusVisibleWithin<HTMLLIElement>(noFocusWithin);
  useScrollToStoreItem(ref, itemId, onScrollSelect);
  return (
    <CardGridItemRoot
      id={id}
      ref={ref}
      className={className}
      as={standalone ? 'div' : 'li'}
      data-testid={dataTestId}
      $focusVisibleWithin={focusVisibleWithin}
      $size={size}
      $standalone={standalone}
      $legacy={legacy}
    >
      {backgroundImageUrl &&
        (typeof backgroundImageUrl === 'string' ? (
          <BackgroundImage
            data-adjustbrightness
            src={backgroundImageUrl}
            alt=""
            $disabled={legacy && disabled}
          />
        ) : (
          <picture>
            <source srcSet={backgroundImageUrl.webp} type="image/webp" />
            <source srcSet={backgroundImageUrl.jpg} type="image/jpeg" />
            <BackgroundImage
              src={backgroundImageUrl.jpg}
              alt=""
              $disabled={legacy && disabled}
            />
          </picture>
        ))}
      <CardGridContent $stretchContent={stretchContent}>
        {children}
      </CardGridContent>
      {onRemove &&
        (legacy ? (
          <LegacyRemoveButton
            onClick={onRemove}
            aria-label="remove from basket"
          >
            <CloseIcon />
          </LegacyRemoveButton>
        ) : (
          <RemoveButton onClick={onRemove} aria-label="remove from basket">
            <TrashIcon />
          </RemoveButton>
        ))}
    </CardGridItemRoot>
  );
}

const CardGridItemRoot = styled.li<{
  $focusVisibleWithin: boolean;
  $size: number;
  $standalone?: boolean;
  $legacy: boolean;
}>`
  position: relative;
  display: flex;
  z-index: 0;
  width: ${(p) => (p.$standalone ? p.$size + 'px' : '100%')};
  height: ${(p) => (p.$standalone ? p.$size + 'px' : 'auto')};
  border: none;
  border-radius: 9px;
  overflow: hidden;
  background: ${(p) =>
    p.$legacy ? p.theme.cardGridItemBgColor : 'rgba(255 255 255 / 20%)'};
  aspect-ratio: 1;
  object-fit: contain;

  &:after {
    content: '';
    width: 1px;
    padding-top: 100%;
  }

  &:focus-within {
    box-shadow: ${(p) =>
      p.$focusVisibleWithin ? `0 0 2px 2px ${p.theme.outlineColor}` : 'none'};
  }

  &:focus-within a:focus {
    outline: none;
  }
`;

const LegacyRemoveButton = styled(LegacyIconButton)`
  position: absolute;
  z-index: 2;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  background: ${(p) => p.theme.chatBgColor};
  opacity: 0.6;
  border-radius: 50%;
  & > svg {
    width: 12px;
    height: 12px;
  }
  cursor: pointer;
  transition: opacity 0.25s ease-out;

  &:hover {
    opacity: 1;
  }
`;

const RemoveButton = styled(IconButton)`
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 5px;
  width: 16px;
  height: 16px;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  border-radius: 4px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 20px;
    height: 20px;
  }

  & > svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 1;
  }
`;

const BackgroundImage = styled.img<{ $disabled?: boolean }>`
  z-index: -1;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: ${(p) => (p.$disabled ? 0.3 : 1)};
`;

const CardGridContent = styled.div<{ $stretchContent?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${(p) =>
    p.$stretchContent ? 'space-between' : 'flex-start'};

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 12px 16px;
  }
`;

type Props = {
  className?: string;
  children: React.ReactNode;
  effectInputs?: any[];
  minItemWidth?: number;
  minItemHeight?: number;
  disableFocusGroup?: boolean;
};

function CardGrid(
  {
    className,
    children,
    effectInputs,
    minItemWidth = 140,
    minItemHeight,
    disableFocusGroup = false,
  }: Props,
  ref: React.Ref<HTMLUListElement>,
) {
  const ulRef = React.useRef<HTMLUListElement>(null);

  useFocusGroup(
    {
      disabled: disableFocusGroup,
      getGroupElements: () => {
        return (
          ulRef.current?.querySelectorAll('[data-cardgridelement="true"]') ?? []
        );
      },
      options: {
        keybindings: {
          prev: { key: 'ArrowLeft' },
          next: { key: 'ArrowRight' },
        },
        wrap: true,
      },
    },
    effectInputs ?? [],
  );

  const mergedRef = useMergedRef(ref, ulRef);

  return (
    <CardGridRoot
      $minItemWidth={minItemWidth}
      $minItemHeight={minItemHeight ?? minItemWidth}
      className={className}
      ref={ref ? mergedRef : ulRef}
    >
      {children}
    </CardGridRoot>
  );
}

// position of this element should NOT be relative or absolute, so that its ::after block
// would be of the size of parent node
export const CardGridItemLink = styled(LegacyAriaButton).attrs({
  'data-cardgridelement': true,
})`
  z-index: 0;
  margin: 0;
  padding: 0;
  border: 0;

  background: transparent;
  color: inherit;
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: none;
  }

  &.focus-visible:focus,
  &:focus-visible:focus {
    box-shadow: none;
  }

  &:after {
    position: absolute;
    z-index: 1;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default Object.assign(React.forwardRef(CardGrid), {
  Item: CardGridItem,
  Link: CardGridItemLink,
});

const CardGridRoot = styled.ul<{
  $minItemWidth: number;
  $minItemHeight: number;
}>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(p) => p.$minItemWidth}px, 1fr)
  );
  grid-auto-rows: minmax(${(p) => p.$minItemHeight}px, auto);
  justify-content: center;
  grid-gap: 8px;
  padding: 0;
`;

const loaderAnimation = keyframes`
  0% {
    left: -10%;
    opacity: 0;
  }

  3% {
    opacity: 1;
  }

  4% {
    opacity: 1;
  }

  7% {
    left: 100%;
    opacity: 0;
  }

  100% {
    left: 100%;
    opacity: 0;
  }
`;

export const LoadingGridItem = styled(CardGridItem)`
  position: relative;
  background: ${(p) => p.theme.loadingCardGridItemBgColor};

  &:before {
    position: absolute;
    content: '';
    display: block;
    height: 100%;
    width: 10%;
    filter: blur(10px);
    top: 0;
    left: -10%;
    background: ${(p) => p.theme.animationBlickColor};
    animation: ${loaderAnimation} 5s linear infinite;
  }

  &:nth-child(2n + 1):before {
    animation-delay: 0.4s;
  }
  &:nth-child(2n):before {
    animation-delay: 0.7s;
  }
`;
