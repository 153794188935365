import { useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { getAvatars } from '../../../actions/avatars';
import { getPersonalBot, updateBot } from '../../../actions/profile';
import { queueSystemNotification } from '../../../actions/ui';
import AriaAlertText from '../../../components/AriaAlertText';
import { AccentButton } from '../../../components/Buttons';
import {
  CustomizationGrid,
  CustomizationGridItem,
} from '../../../components/CustomizationGrid';
import {
  StoreCategoryGridSectionHeader,
  getGridItemWidth,
} from '../../../components/StoreCategoryGrid/StoreCategoryGridSection';
import { useMobileQuery } from '../../../components/responsive';
import { avatarAtom, avatarProfileStorePreviewAtom } from '../../../core/atoms';
import { BotPatch, CategoryTreeNode } from '../../../types/models';
import { ApiError } from '../../../utils/apiError';
import {
  getAccessoriesVariations,
  getLookVariations,
  refillVariations,
} from '../../../utils/storeVariations';
import useApi from '../../../utils/useApi';

type Props = {
  onAvatarTypeChange: (avatarType: string) => void;
  onVisible: (index: number, visible: boolean) => void;
  index: number;
};

export const ChangeAvatarId = 'change-avatar-block';

export const ChangeAvatarCategoryNode: CategoryTreeNode = {
  id: ChangeAvatarId,
  name: 'Avatar',
  long_name: 'Avatar',
  description: 'Avatar',
  children: [],
  num_items: 16,
  camera_slot: 'default_store',
};

function ChangeAvatar({ index, onAvatarTypeChange, onVisible }: Props) {
  const dispatch = useDispatch();

  const { ref } = useInView({
    onChange(inView) {
      onVisible?.(index, inView);
    },
  });

  const bot = useApi((state) => state.profile.persist.bot, getPersonalBot, {
    memoDeepEqual: true,
  });

  const [model, setModel] = useState(bot?.avatar_v2);

  const setAvatarPreview = useSetAtom(avatarProfileStorePreviewAtom);
  const avatar = useAtomValue(avatarAtom);

  const debouncedUpdateBot = useMemo(() => {
    return debounce(async (data: BotPatch) => {
      try {
        await dispatch(updateBot(data));
      } catch (e) {
        console.error(e);
        if (e instanceof ApiError) {
          dispatch(queueSystemNotification(e.message, 'warning'));
        }
      }
    }, 500);
  }, []);

  const models = useApi((state) => state.avatars.modelsV2, getAvatars, {
    memoDeepEqual: true,
  });

  const isMobile = useMobileQuery();
  const itemWidth = getGridItemWidth(isMobile);

  return (
    <div id={`grid-section-${ChangeAvatarId}`} ref={ref}>
      <StoreCategoryGridSectionHeader>Avatar</StoreCategoryGridSectionHeader>
      <CustomizationGrid minItemWidth={itemWidth} effectInputs={[]}>
        {models.map((m) => {
          return (
            <CustomizationGridItem
              checked={m.avatar_type === model?.avatar_type}
              size={itemWidth}
              key={m.id}
              backgroundImageUrl={m.model.ios.small_icon_url}
              onClick={() => {
                const activeVariations = refillVariations(
                  [
                    ...getLookVariations(m.active_variations),
                    ...getAccessoriesVariations(avatar.variations ?? []),
                  ],
                  m.active_variations,
                );
                const model = {
                  ...m,
                  active_variations: activeVariations.filter(
                    (v) => !!v.bought_count,
                  ),
                  age: avatar.age ?? 0,
                  body_type: avatar.bodyType ?? undefined,
                };
                setModel(model);
                debouncedUpdateBot({ avatar_v2: model });
                setAvatarPreview({
                  type: m.avatar_type,
                  age: avatar.age,
                  variations: activeVariations,
                  bodyType: avatar.bodyType,
                  gender: m.gender,
                  roomItems: avatar.roomItems,
                });
                onAvatarTypeChange(m.avatar_type);
              }}
            />
          );
        })}
      </CustomizationGrid>
    </div>
  );
}

export default ChangeAvatar;

export const SubmitError = styled(AriaAlertText)`
  flex: 0 0 auto;
  max-height: 16px;
  max-width: 300px;
  margin: 10px auto;
  color: #fe9e98;
  font-size: 14px;
  line-height: 16px;
  transition: max-height 0.5s ease-out;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin: 20px auto;
    -webkit-line-clamp: 2;
    max-height: 32px;
  }
`;

export const SubmitButton = styled(AccentButton)`
  height: 34px;
  padding: 3px 20px;
`;
