import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import XpBar from './XpBar';

export const XpBarWithMood = () => {
  const bot = useSelector((state) => state.profile.persist.bot);
  const lvl = bot?.stats?.current_level?.level_index;
  const mood = bot?.mood_v2?.name;

  return !!lvl ? (
    <StyledXpBar bot={bot}>
      <XpInfo>
        LVL {lvl}
        {mood && (
          <>
            {' '}
            <Dot /> {mood}
          </>
        )}
      </XpInfo>
    </StyledXpBar>
  ) : null;
};

export const StyledXpBar = styled(XpBar)`
  width: 122px;
  height: 34px;
  border-radius: 14px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 145px;
    height: 44px;
    border-radius: 19px;
  }
`;

const XpInfo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const Dot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  font-size: 20px;
  margin: 0 4px -2px;
  opacity: 0.5;
  background-color: ${(p) => p.theme.dimmedFgColor};
`;
