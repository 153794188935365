import { useAtomValue } from 'jotai';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFeatureFlags } from 'src/core/FeatureFlags/queries';
import styled, { css } from 'styled-components/macro';
import AriaAlertText from '../../../components/AriaAlertText';
import AvatarFallback from '../../../components/AvatarFallback';
import { AccentButton, IconButton } from '../../../components/Buttons';
import CanvasView from '../../../components/CanvasView';
import { Desktop, Mobile } from '../../../components/responsive';
import {
  avatarHiddenAtom,
  avatarProgressAtom,
  avatarStateAtom,
} from '../../../core/atoms';
import { ReactComponent as ArrowIcon } from '../../../icons/ArrowBack.svg';
import { ReactComponent as CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as ReplikaLogoIcon } from '../../../icons/ReplikaLogo.svg';
import mobileMedia from '../../../utils/mobileMedia';
import { avatarIdAtom, stylizedAvatarIdAtom } from '../atoms';
import { stylizedAvatars } from '../consts';
import useUpdateDefaultModel from '../useUpdateDefaultModel';
import CoverVideo from './CoverVideo';

type Props = {
  className?: string;
  mode: 'left' | 'split' | 'right';
  preloadUnity?: boolean;
  children: React.ReactNode;
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  collapsed?: boolean;
  headerButtonTo?: string;
  headerButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  headerButtonIcon?: 'arrow-back' | 'close';
  headerButtonPosition?: 'none' | 'left' | 'right';
  headerButtonLabel?: 'back' | 'close';
  background?: 'onboarding' | 'subscription';
  videoPaused?: boolean;
  hideLogo?: boolean;
};

function AuthLayout({
  className,
  children,
  mode,
  preloadUnity,
  headerButtonTo,
  header,
  subheader,
  collapsed,
  headerButtonOnClick,
  headerButtonIcon = 'arrow-back',
  headerButtonPosition = 'left',
  headerButtonLabel,
  background,
  videoPaused,
  hideLogo,
}: Props) {
  const onboardingStatus = useSelector(
    (state) => state.signup.persist.onboardingStatus,
  );

  useFeatureFlags(onboardingStatus !== 'initial');
  let sub = subheader && <SubTitle>{subheader}</SubTitle>;

  const headerButton = (
    <HeaderButton
      replace
      to={headerButtonTo}
      onClick={headerButtonOnClick}
      label={headerButtonLabel}
      $position={headerButtonPosition}
    >
      {headerButtonIcon === 'arrow-back' && <ArrowIcon />}
      {headerButtonIcon === 'close' && <CloseIcon />}
    </HeaderButton>
  );

  const avatarId = useAtomValue(avatarIdAtom);
  const stylizedAvatarId = useAtomValue(stylizedAvatarIdAtom);

  const updateDefaultModel = useUpdateDefaultModel();

  React.useEffect(() => {
    if (avatarId) return;
    updateDefaultModel();
  }, [avatarId, updateDefaultModel]);

  const style =
    background === 'subscription'
      ? ({
          background:
            'linear-gradient(180deg, #FFA26D 0%, #8371F2 50%, #352562 100%)',
        } as const)
      : undefined;

  const progress = useAtomValue(avatarProgressAtom);

  const avatarState = useAtomValue(avatarStateAtom);
  const avatarHidden = useAtomValue(avatarHiddenAtom);

  const fallback = (
    <>
      <Mobile>
        <MobileOnboardingFallback
          avatarType={stylizedAvatarId}
          avatarImageBaseUrl={stylizedAvatars[stylizedAvatarId].image}
        />
      </Mobile>
      <Desktop>
        <AvatarFallback
          avatarType={stylizedAvatarId}
          avatarImageBaseUrl={stylizedAvatars[stylizedAvatarId].image}
        />
      </Desktop>
    </>
  );

  return (
    <AuthLayoutRoot className={className} style={style}>
      <LeftPanel $mode={mode}>
        <Cover>
          <StyledCoverVideo paused={videoPaused} />
          <CoverOverlay />
        </Cover>
        <Header $visible={mode === 'left'} aria-hidden={mode !== 'left'}>
          {!hideLogo && <ReplikaLogoIcon />}
        </Header>
        {mode === 'left' ? children : null}
      </LeftPanel>
      <RightPanel $mode={mode}>
        <Header $visible={mode !== 'left'} aria-hidden={mode === 'left'}>
          {headerButtonPosition === 'left' &&
            (headerButtonTo || headerButtonOnClick) &&
            headerButton}
          <Desktop>{!hideLogo && <ReplikaLogoIcon />}</Desktop>
          <Mobile>
            <Title>{header}</Title>
          </Mobile>
          {headerButtonPosition === 'right' &&
            (headerButtonTo || headerButtonOnClick) &&
            headerButton}
        </Header>
        <Mobile>{sub}</Mobile>

        {avatarState === '3d' && (
          <AvatarCanvasView
            priority={1}
            id="subscription-avatar"
            $visible={!avatarHidden}
            canvasId="avatar-canvas"
            loading={progress < 1}
          >
            {fallback}
          </AvatarCanvasView>
        )}
        {avatarState === '2d' && !avatarHidden && fallback}
        {mode !== 'left' ? children : null}
      </RightPanel>
    </AuthLayoutRoot>
  );
}

export default AuthLayout;

const MobileOnboardingFallback = styled(AvatarFallback)`
  position: relative;
  height: 45vh;
  height: 45svh;
  margin: 2vh 0 -2vh;

  & img {
    margin: 0 auto;
  }
`;

const AvatarCanvasView = styled(CanvasView)<{ $visible: boolean }>`
  display: ${(p) => (p.$visible ? 'block' : 'none')};
  position: relative;
  width: 100%;
  height: 45vh;

  @media ${(p) => p.theme.breakpoints.tablet} {
    display: block;
    position: absolute;
    top: ${(p) => (p.$visible ? 0 : -1)};
    left: 0;
    width: ${(p) => (p.$visible ? '100vw' : '1px')};
    height: ${(p) => (p.$visible ? '100vh' : '1px')};
    line-height: 0;
  }
`;

const AuthLayoutRoot = styled.div`
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  height: 100dvh;

  display: flex;

  background: linear-gradient(
    180deg,
    #6b83da 0%,
    #203378 50%,
    rgba(32 51 120 / 0) 100%
  );
  color: ${(p) => p.theme.fgColor};

  @media (orientation: landscape) {
    background: ${(p) => p.theme.fullscreenBgColors.default};
  }
`;

const LeftPanel = styled.div<{ $mode: 'left' | 'split' | 'right' }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: ${(p) =>
    p.$mode === 'left' ? '100%' : p.$mode === 'split' ? '50%' : 0};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: ${(p) =>
    p.$mode === 'split' ? 'max-width 0.5s ease-out' : 'none'};

  ${mobileMedia`
    max-width: ${(p) => (p.$mode === 'left' ? '100%' : 0)};
  `}
`;

const RightPanel = styled.div<{ $mode: 'left' | 'split' | 'right' }>`
  position: relative;
  max-width: ${(p) =>
    p.$mode === 'left' ? 0 : p.$mode === 'split' ? '50%' : '100%'};
  opacity: ${(p) => (p.$mode === 'left' ? 0 : 1)};
  flex: 1 1 auto;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ${(p) =>
    p.$mode === 'split'
      ? 'max-width 0.5s ease-out, opacity 0.5s ease-out'
      : 'none'};

  ${mobileMedia`
    max-width: ${(p) => (p.$mode === 'left' ? 0 : '100%')};
  `}
`;

const Cover = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const StyledCoverVideo = styled(CoverVideo)`
  width: 100%;
  object-fit: cover;
  min-height: 50vh;
  min-height: 50dvh;
  max-height: 100vh;
  max-height: 100dvh;

  @media (orientation: landscape) {
    min-height: 100vh;
    min-height: 100dvh;
  }
`;

const CoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #203378 0%, rgba(32 51 120 / 0) 100%);
`;

const Header = styled.header<{ $visible?: boolean }>`
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  position: relative;
  width: 100%;
  padding: 8px 0 0;

  z-index: 2;
  transition: opacity 0.5s ease-out;
  text-align: center;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 32px 0 0;
  }
`;

const HeaderButton = styled(IconButton)<{
  $position: 'none' | 'left' | 'right';
}>`
  position: absolute;
  left: ${(p) => (p.$position === 'left' ? '30px' : 'auto')};
  right: ${(p) => (p.$position === 'right' ? '30px' : 'auto')};
  top: 30px;
  background: rgba(0 0 0 / 10%);
  width: 44px;
  height: 44px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: 32px;
  }

  ${mobileMedia`
    width: 34px;
    height: 34px;
    left: ${(p) => (p.$position === 'left' ? '15px' : 'auto')};
    right: ${(p) => (p.$position === 'right' ? '15px' : 'auto')};
    top: 15px;

    & > svg {
      width: 24px;
    }
  `}
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0 20px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 28px;
    line-height: 34px;
    max-width: 420px;
  }
`;

// FIXME
export const DesktopTitle = styled(Title)`
  display: none;

  @media ${(p) => p.theme.breakpoints.tablet} {
    display: block;
    margin-bottom: 30px;
  }
`;

export const SubTitle = styled.p`
  font-family: ${(p) => p.theme.fonts.display};
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.12px;
  white-space: pre-wrap;
`;

export const DesktopSubtitle = styled(SubTitle)`
  display: none;

  @media ${(p) => p.theme.breakpoints.tablet} {
    display: block;
  }
`;

export const AuthHeader = styled.header`
  margin-bottom: 30px;

  ${Title} {
    margin-top: 0;
    margin-bottom: 10px;
    max-width: 100%;
  }

  ${SubTitle} {
    margin: 0;
  }
`;

export const DesktopHeader = styled(AuthHeader)`
  display: none;

  @media ${(p) => p.theme.breakpoints.tablet} {
    display: block;
  }
`;

export function AuthForm({
  children,
  innerStyle,
  ...rest
}: React.ComponentPropsWithoutRef<'form'> & {
  innerStyle?: React.CSSProperties;
}) {
  return (
    <AuthFormRoot {...rest}>
      <AuthFormInner style={innerStyle}>{children}</AuthFormInner>
    </AuthFormRoot>
  );
}

const AuthFormRoot = styled.form`
  display: flex;

  /* forces flex-shrink */
  min-height: 0;

  flex: 1 1 auto;
  flex-direction: column;
  width: 100vw;
  max-width: 600px;
  justify-content: flex-start;

  margin: -40px auto 0;
  padding-top: 10vh;
  padding-top: 10dvh;

  @media ${(p) => p.theme.breakpoints.tablet} {
    justify-content: center;
    width: 350px;
    padding-top: 0;
    max-width: auto;
    margin: -40px auto;
  }
`;

export const AuthFormInner = styled.div`
  flex: 1 1 auto;
  display: flex;
  gap: 10px;
  flex-direction: column;
  min-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  justify-content: flex-start;
  padding: 15px;

  & > * {
    flex-shrink: 0;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    flex: 0 1 auto;
    margin: 0 -32px;
    padding: 40px 32px min(20vh, 75px);
  }
`;

export const AuthFormWithAvatar = styled(AuthForm)`
  position: relative;
  z-index: 1;
  align-self: stretch;

  & > div {
    align-items: center;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding-left: 20px;
    padding-right: 20px;
    align-self: flex-start;
    width: 600px;
    margin-left: min(45%, 100vw - 600px);
  }
`;

export function SubmitError({
  error,
  className,
  placeholder,
}: {
  error: null | string;
  className?: string;
  placeholder?: React.ReactElement;
}) {
  return error == null ? (
    placeholder ?? <div style={{ maxHeight: 10, height: 10 }} />
  ) : (
    <SubmitErrorRoot className={className}>{error}</SubmitErrorRoot>
  );
}

export const SubmitErrorRoot = styled(AriaAlertText)`
  color: #fe9e98;

  max-height: 48px;

  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;

  transition: max-height 0.5s ease-out;
`;

export const SubmitButton = styled(AccentButton)<{ $dimmedAccent?: boolean }>`
  flex: 0 0 auto;
  align-self: center;
  margin: 10px 0 0;
  width: 100%;

  ${(p) =>
    p.$dimmedAccent
      ? css`
          box-shadow: 0px 0px 20px 0px rgba(209, 209, 209, 0.9);

          &:hover {
            box-shadow: 0px 0px 25px 0px rgba(209, 209, 209, 0.9);
          }
        `
      : ''}

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: auto;
  }
`;
