import { replace } from 'connected-react-router';
import { sortBy, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ModalForm } from 'src/routes/modals/ModalForm';
import ModalRouteMatch, {
  ModalRoute,
} from 'src/routes/modals/ModalRoutes/ModalRouteMatch';
import styled from 'styled-components/macro';
import { uploadImage } from '../../../../../actions/image';
import { queueSystemNotification } from '../../../../../actions/ui';
import CircleSpinner from '../../../../../components/CircleSpinner';
import CroppedPhoto from '../../../../../components/CroppedPhoto';
import FormSelect from '../../../../../components/Form/FormSelect';
import { StaticallyLabledTextInput } from '../../../../../components/Inputs';
import { FeatureFlags } from '../../../../../core/featureFlags';
import getText from '../../../../../core/texts/getText';
import {
  MemoryFactType,
  MetricsEvents,
  ModalRoutes,
  Routes,
} from '../../../../../types/enums';
import { PhotoFrame, isMemoryCategoryType } from '../../../../../types/models';
import { SubmitStatus } from '../../../../../types/states';
import { IMAGE_MAX_PIXEL_AREA } from '../../../../../utils/constants';
import { captureError } from '../../../../../utils/initSentry';
import toModalRoute from '../../../../../utils/toModalRoute';
import useApi from '../../../../../utils/useApi';
import useBotName from '../../../../../utils/useBotName';
import useForm from '../../../../../utils/useForm';
import useGoBack from '../../../../../utils/useGoBack';
import useLogEvent from '../../../../../utils/useLogEvent';
import useUserName from '../../../../../utils/useUserName';
import {
  createMemoryFact,
  createMemoryPerson,
  getMemoryCategories,
  getMemoryRelations,
} from '../../../actions';
import ImageCropper from '../ImageCropper';
import * as MemoryForm from '../MemoryForm';

type Props = {
  setFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

type FieldValues = {
  category: string;
  name: string;
  relationType: string;
  relationId: string;
  text: string;
};

// TODO: reuse SettingsLayout / SettingsForm?
const AddMemoryForm = ({ setFormDirty }: Props) => {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const { goBack } = useGoBack({
    fallbackRoute: Routes.Memory,
  });

  const location = useLocation<{
    category: string;
  }>();

  const { category } = location.state ?? {};

  const [factType, setFactType] = useState<MemoryFactType>(
    MemoryFactType.RobotFacts,
  );

  const botName = useBotName();
  const username = useUserName();

  const memoryCategories = useApi(
    (state) => {
      if (FeatureFlags.statementsAboutReplika) {
        return state.memory.persist.memoryCategories.filter(
          (c) =>
            !isMemoryCategoryType(c, 'replika_fact') &&
            !isMemoryCategoryType(c, 'temporary_fact'),
        );
      } else {
        return state.memory.persist.memoryCategories;
      }
    },
    getMemoryCategories,
    { memoDeepEqual: true },
  );

  const sortedMemoryCategories = useMemo(
    () =>
      sortBy(memoryCategories, 'name').map((category) => ({
        value: category.id,
        label: category.name,
      })),
    [memoryCategories],
  );

  // it's guranteed that there is at least one category
  // with is_default = true
  const defaultCategory = memoryCategories.find(
    (category) => category.is_default,
  );

  // it's guranteed that there is at least one category of 'person' type
  const personsCategory = memoryCategories.find((category) =>
    isMemoryCategoryType(category, 'person'),
  );

  const relations = useApi(
    (state) => state.memory.persist.relations,
    getMemoryRelations,
    { memoDeepEqual: true },
  );
  const sortedRelations = useMemo(() => sortBy(relations, 'name'), [relations]);
  const relationCategories = useMemo(
    () =>
      uniq(
        relations.reduce<string[]>(
          (acc, { category }) => [...acc, category],
          [],
        ),
      ),
    [relations],
  );

  const defaultCategoryId = defaultCategory ? defaultCategory.id : '';
  const personsCategoryId = personsCategory ? personsCategory.id : '';
  const inititalCategory =
    category === 'persons' ? personsCategoryId : defaultCategoryId;

  const {
    isDirty,
    handleSubmit,
    reset,
    resetField,
    isSubmitting,
    errors,
    register,
    watch,
    setValue,
    unregister,
  } = useForm<FieldValues>({
    defaultValues: {
      category: inititalCategory,
      name: undefined,
      relationType: undefined,
      relationId: undefined,
      text: '',
    },
  });

  const [watchCategory, watchName, watchRelationType, watchRelationId] = watch([
    'category',
    'name',
    'relationType',
    'relationId',
  ]);

  const isPerson = personsCategory
    ? watchCategory === personsCategory.id
    : false;

  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [imageFile, setImageFile] = useState<File | undefined>();
  const [imageUploadStatus, setImageUploadStatus] =
    useState<SubmitStatus>('initial');
  const [frame, setFrame] = useState<PhotoFrame | undefined>(undefined);

  useEffect(() => {
    if (imageUrl) {
      setFormDirty(true);
    } else {
      setFormDirty(isDirty);
    }
  }, [setFormDirty, isDirty, imageUrl]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  // TODO: split into separate forms instead
  useEffect(() => {
    if (isPerson) {
      unregister('text');
    } else {
      unregister('name');
      unregister('relationType');
      unregister('relationId');
    }
  }, [isPerson, unregister]);

  useEffect(() => {
    const currentCategory = memoryCategories.find(
      (category) => category.id === watchCategory,
    );
    if (!currentCategory) return;

    if (isMemoryCategoryType(currentCategory, 'replika_fact')) {
      setFactType(MemoryFactType.RobotFacts);
    } else {
      setFactType(MemoryFactType.CustomerFacts);
    }
  }, [memoryCategories, watchCategory]);

  const handleCropperClose = () => {
    setImageUrl(undefined);

    dispatch(replace(toModalRoute(ModalRoutes.MemoryRecordAdd)));
  };

  const handleCropperSave = async (frameData: PhotoFrame) => {
    setFrame({
      height: frameData.height,
      width: frameData.width,
      upper_left_x: frameData.upper_left_x,
      upper_left_y: frameData.upper_left_y,
    });

    dispatch(replace(toModalRoute(ModalRoutes.MemoryRecordAdd)));
  };

  const handleImageSave = async () => {
    if (!imageFile) return;

    try {
      setImageUploadStatus('submitting');

      const { image_url: savedImageUrl } = await dispatch(
        uploadImage(imageFile?.slice(), IMAGE_MAX_PIXEL_AREA),
      );

      setImageUploadStatus('success');

      return savedImageUrl;
    } catch (e) {
      setImageUploadStatus('error');
      captureError(e);

      return Promise.reject(e);
    }
  };

  const handleImageUpload = (files: File[]) => {
    if (files[0]) {
      setImageFile(files[0]);

      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as any);
        dispatch(replace(toModalRoute(ModalRoutes.MemoryRecordAddImageUpload)));
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handlePersonSubmit = async (data: FieldValues) => {
    const { name, relationId } = data;

    try {
      const savedImageUrl = await handleImageSave();

      await dispatch(
        createMemoryPerson({
          name,
          relation_id: relationId,
          ...(!!savedImageUrl
            ? {
                photo: {
                  photo_url: savedImageUrl,
                  ...(frame ? { frame } : {}),
                },
              }
            : {}),
        }),
      );

      const relation = relations.find((relation) => relation.id === relationId);

      logEvent(MetricsEvents.PersonOrPetFactAdded, {
        type: relation?.category.toLowerCase() ?? 'other',
        relationship: relation?.name.toLowerCase() ?? 'other',
      });
    } catch (e) {
      dispatch(
        queueSystemNotification(
          e instanceof Error ? e.message : 'Something went wrong',
          'warning',
        ),
      );

      return captureError(e);
    }

    reset();
    goBack();
  };

  const handleFactSubmit = async (data: FieldValues) => {
    const { text } = data;

    try {
      await dispatch(
        createMemoryFact(factType, {
          ...(watchCategory ? { category_id: watchCategory } : {}),
          text,
        }),
      );

      const isCustomerFact = factType === MemoryFactType.CustomerFacts;

      const { name: categoryName } = isCustomerFact
        ? memoryCategories.find(
            (category) => category.id === watchCategory,
          ) ?? {
            name: 'other',
          }
        : { name: 'other' };

      logEvent(MetricsEvents.FactAdded, {
        category: categoryName.toLowerCase(),
        about: isCustomerFact ? 'user' : 'bot',
      });

      reset();
      goBack();
    } catch (e) {
      dispatch(
        queueSystemNotification(
          e instanceof Error ? e.message : 'Something went wrong',
          'warning',
        ),
      );

      captureError(e);
    }
  };

  const handleFormSubmit = async (data: FieldValues) => {
    return isPerson ? handlePersonSubmit(data) : handleFactSubmit(data);
  };

  let placeholder = '';
  let hint = '';

  if (!isPerson) {
    if (factType === MemoryFactType.RobotFacts) {
      placeholder = getText('memory_screen_new_fact_empty_input_replika', {
        replika_name: botName,
      });
      hint = getText('memory_screen_new_fact_hint_replika', {
        replika_name: botName,
      });
    } else {
      placeholder = getText('memory_screen_new_fact_empty_input_user', {
        user_name: username,
      });
      hint = getText('memory_screen_new_fact_hint_user', {
        user_name: username,
      });
    }
  }

  return (
    <ModalRouteMatch>
      <ModalRoute exact path={ModalRoutes.MemoryRecordAdd}>
        <ModalForm.Wrapper>
          <ModalForm.Body onSubmit={handleSubmit(handleFormSubmit)}>
            <StyledStaticallyLabeledSelect
              id="select-memory-category"
              label="Category"
              {...register('category', {
                required: true,
              })}
              defaultValue={watchCategory ?? inititalCategory}
              onChange={(value) => {
                setValue('category', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                });

                resetField('text');
                resetField('name');
                resetField('relationType');
                resetField('relationId');
                setImageUrl(undefined);
              }}
              options={sortedMemoryCategories}
            />

            {isPerson ? (
              <>
                {imageUrl && imageUploadStatus !== 'submitting' ? (
                  <StyledCroppedPhoto
                    width={184}
                    height={184}
                    label={`${watchName ?? 'Person'}'s photo`}
                    photoUrl={imageUrl}
                    photoFrame={frame}
                  />
                ) : imageUploadStatus === 'submitting' ? (
                  <SpinnerContainer>
                    <StyledSpinner lineWidth={10} />
                  </SpinnerContainer>
                ) : (
                  <MemoryForm.UploadPersonImageButton
                    id="upload-person-image"
                    onUpload={handleImageUpload}
                  />
                )}

                <>
                  <StyledStaticallyLabledTextInput
                    id="add-person-name"
                    placeholder="Add"
                    staticLabel="Name"
                    {...register('name', {
                      required: true,
                    })}
                    autoComplete="off"
                    aria-invalid={!!errors.name}
                  />

                  {errors.name?.message && (
                    <ModalForm.FieldError error={errors.name.message} />
                  )}
                </>

                <>
                  <StyledStaticallyLabeledSelect
                    id="select-person-life-area"
                    placeholder="Add"
                    label="Type"
                    {...register('relationType', {
                      required: true,
                    })}
                    defaultValue={watchRelationType}
                    onChange={(value) => {
                      setValue('relationType', value, {
                        shouldDirty: true,
                        shouldValidate: true,
                        shouldTouch: true,
                      });

                      resetField('relationId');
                    }}
                    options={relationCategories.reduce<{ value: string }[]>(
                      (accum, curr) => [...accum, { value: curr }],
                      [],
                    )}
                    invalid={!!errors.relationType}
                  />
                  {errors.relationType?.message && (
                    <ModalForm.FieldError error={errors.relationType.message} />
                  )}
                </>

                {watchRelationType ? (
                  <>
                    <StyledStaticallyLabeledSelect
                      id="select-person-relastionship"
                      {...(watchRelationId ? { key: watchRelationType } : {})}
                      placeholder="Add"
                      label="Relationship"
                      {...register('relationId', {
                        required: true,
                      })}
                      defaultValue={watchRelationId}
                      onChange={(value) =>
                        setValue('relationId', value, {
                          shouldDirty: true,
                          shouldValidate: true,
                          shouldTouch: true,
                        })
                      }
                      options={sortedRelations.reduce<
                        { value: string; label: string }[]
                      >(
                        (accum, curr) =>
                          curr.category === watchRelationType
                            ? [...accum, { value: curr.id, label: curr.name }]
                            : accum,
                        [],
                      )}
                      invalid={!!errors.relationId}
                    />
                    {errors.relationId?.message && (
                      <ModalForm.FieldError error={errors.relationId.message} />
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <>
                <StyledStaticallyLabeledSelect
                  id="select-memory-about"
                  label="About"
                  value={factType}
                  onChange={(value) => {
                    setFactType(value as MemoryFactType);
                  }}
                  options={[
                    {
                      value: MemoryFactType.RobotFacts,
                      label: botName,
                    },
                    {
                      value: MemoryFactType.CustomerFacts,
                      label: 'You',
                    },
                  ]}
                />
                <FactTextArea
                  id="add-fact-text"
                  key={watchCategory}
                  maxLength={200}
                  placeholder={placeholder}
                  {...register('text', {
                    required: true,
                  })}
                  onChange={(e) =>
                    setValue('text', e.target.value, {
                      shouldDirty: true,
                      shouldValidate: true,
                      shouldTouch: true,
                    })
                  }
                  aria-invalid={!!errors.text}
                />
                {errors.text?.message && (
                  <ModalForm.FieldError error={errors.text.message} />
                )}
                {hint && !errors.text?.message && (
                  <ModalForm.FieldHint>{hint}</ModalForm.FieldHint>
                )}
              </>
            )}

            <ModalForm.Actions>
              <ModalForm.SaveButton
                type="submit"
                showSpinner={isSubmitting}
                disabled={!isDirty && !imageFile}
              >
                Save
              </ModalForm.SaveButton>
            </ModalForm.Actions>
          </ModalForm.Body>
        </ModalForm.Wrapper>
      </ModalRoute>

      <ModalRoute exact path={ModalRoutes.MemoryRecordAddImageUpload}>
        <ImageCropper
          imageUrl={imageUrl}
          handleClose={handleCropperClose}
          handleSave={handleCropperSave}
        />
      </ModalRoute>
    </ModalRouteMatch>
  );
};

export default AddMemoryForm;

const StyledStaticallyLabeledSelect = styled(FormSelect)`
  margin-top: 10px;
`;

const StyledStaticallyLabledTextInput = styled(StaticallyLabledTextInput)`
  margin-top: 30px;
`;

const FactTextArea = styled(ModalForm.TextArea)`
  margin-top: 10px;
`;

const StyledCroppedPhoto = styled(CroppedPhoto)`
  margin-top: 40px;
`;

const SpinnerContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 184px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
`;

const StyledSpinner = styled(CircleSpinner)`
  width: 54px;
  height: 54px;
`;
