import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalForm } from 'src/routes/modals/ModalForm';
import styled from 'styled-components/macro';
import {
  queueSystemNotification,
  setActiveDialog,
} from '../../../../../actions/ui';
import FormSelect from '../../../../../components/Form/FormSelect';
import LoadingPage from '../../../../../components/LoadingPage';
import { FeatureFlags } from '../../../../../core/featureFlags';
import getText from '../../../../../core/texts/getText';
import {
  Dialogs,
  MemoryFactType,
  MetricsEvents,
  Routes,
} from '../../../../../types/enums';
import {
  MemoryCategory,
  MemoryFactPatch,
  MemoryFactWithType,
  isCustomerFactWithCategory,
  isMemoryCategoryType,
} from '../../../../../types/models';
import { SubmitStatus } from '../../../../../types/states';
import { captureError } from '../../../../../utils/initSentry';
import useApi from '../../../../../utils/useApi';
import useBotName from '../../../../../utils/useBotName';
import useForm from '../../../../../utils/useForm';
import useGoBack from '../../../../../utils/useGoBack';
import useLogEvent from '../../../../../utils/useLogEvent';
import useUserName from '../../../../../utils/useUserName';
import {
  deleteMemory,
  getMemory,
  getMemoryCategories,
  updateMemoryFact,
} from '../../../actions';

type EditFactFormWrapperProps = {
  memoryId: string;
  setFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

type EditFactFormProps = EditFactFormWrapperProps & {
  fact: MemoryFactWithType;
};

type FieldValues = {
  category: string;
  text: string;
};

// TODO: reuse SettingsLayout / SettingsForm

function EditFactForm({ fact, memoryId, setFormDirty }: EditFactFormProps) {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const botName = useBotName('your Replika');
  const username = useUserName();

  const { goBack } = useGoBack({
    fallbackRoute: Routes.Memory,
  });

  const [deleteStatus, setDeleteStatus] = useState<SubmitStatus>('initial');

  const memoryCategories = useApi(
    (state) => state.memory.persist.memoryCategories,
    getMemoryCategories,
    { memoDeepEqual: true },
  );

  const hasCategory = isCustomerFactWithCategory(fact);
  const isCustomerFact = fact.factType === MemoryFactType.CustomerFacts;

  const [factType, setFactType] = useState(fact.factType);

  let factCategory: MemoryCategory | undefined;

  if (FeatureFlags.statementsAboutReplika) {
    factCategory =
      'category_id' in fact
        ? memoryCategories.find((category) => category.id === fact.category_id)
        : undefined;
  } else {
    factCategory =
      // only customer facts have category_id
      hasCategory
        ? memoryCategories.find((category) => category.id === fact.category_id)
        : memoryCategories.find((category) =>
            isMemoryCategoryType(category, 'replika_fact'),
          );
  }

  const {
    isDirty,
    handleSubmit,
    reset,
    resetField,
    isSubmitting,
    errors,
    register,
    watch,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: {
      category: factCategory?.id,
      text: fact.text,
    },
  });

  const [watchCategoryId, watchText] = watch(['category', 'text']);
  const watchCategory = memoryCategories.find((c) => c.id === watchCategoryId);

  const availableCategories = useMemo(() => {
    if (FeatureFlags.statementsAboutReplika) {
      const possibleReplacements = factCategory?.possible_replacements ?? [];
      return memoryCategories.filter((c) => {
        // show replika_fact category if it is selected, but disable it as soon as we switch to another category
        if (
          watchCategory &&
          isMemoryCategoryType(c, 'replika_fact') &&
          !isMemoryCategoryType(watchCategory, 'replika_fact')
        ) {
          return false;
        }
        return possibleReplacements.includes(c.id);
      });
    } else {
      return memoryCategories;
    }
  }, [memoryCategories, factCategory, watchCategory]);

  const sortedMemoryCategories = useMemo(
    () =>
      sortBy(availableCategories, 'name').map((category) => ({
        value: category.id,
        label: category.name,
      })),
    [availableCategories],
  );

  useEffect(() => setFormDirty(isDirty), [setFormDirty, isDirty]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const handleFactDelete = async () => {
    try {
      setDeleteStatus('submitting');

      await dispatch(deleteMemory(fact.factType, memoryId));

      const { name: categoryName } = hasCategory
        ? memoryCategories.find(
            (category) => category.id === fact.category_id,
          ) ?? { name: 'other' }
        : { name: 'other' };

      logEvent(MetricsEvents.FactDeleted, {
        category: categoryName.toLowerCase(),
        about: isCustomerFact ? 'user' : 'bot',
      });

      setDeleteStatus('success');

      goBack();
    } catch (e) {
      setDeleteStatus('error');

      dispatch(
        queueSystemNotification(
          e instanceof Error ? e.message : 'Something went wrong',
          'warning',
        ),
      );

      captureError(e);
    }
  };

  const handleDeleteButtonClick = () => {
    dispatch(
      setActiveDialog({
        type: Dialogs.Confirmation,
        content: {
          title: 'Delete a fact?',
          description: `This action will make ${botName} forget all information about this topic.`,
          secondaryText: 'Delete',
          primaryText: 'Cancel',
        },
        onSecondaryClick: handleFactDelete,
      }),
    );
  };

  const handleFormSubmit = async (data: FieldValues) => {
    const { text } = data;

    try {
      const memoryFactPatch: MemoryFactPatch = {
        ...(watchCategoryId ? { category_id: watchCategoryId } : {}),
        text,
      };

      if (factType !== fact.factType) {
        memoryFactPatch.fact_type = factType;
      }

      await dispatch(
        updateMemoryFact(fact.factType, memoryId, memoryFactPatch),
      );

      const { name: categoryName } = hasCategory
        ? memoryCategories.find(
            (category) => category.id === watchCategoryId,
          ) ?? {
            name: 'other',
          }
        : { name: 'other' };

      logEvent(MetricsEvents.FactEdited, {
        category: categoryName.toLowerCase(),
        about: isCustomerFact ? 'user' : 'bot',
      });

      reset({}, { keepValues: true });
      goBack();
    } catch (e) {
      dispatch(
        queueSystemNotification(
          e instanceof Error ? e.message : 'Something went wrong',
          'warning',
        ),
      );

      captureError(e);
    }
  };

  let categoryDisabled = true;

  if (FeatureFlags.statementsAboutReplika) {
    categoryDisabled = factCategory
      ? isMemoryCategoryType(factCategory, 'person')
      : true;
  }

  let placeholder = '';
  let hint = '';

  if (factType === MemoryFactType.RobotFacts) {
    placeholder = getText('memory_screen_new_fact_empty_input_replika', {
      replika_name: botName,
    });
    hint = getText('memory_screen_new_fact_hint_replika', {
      replika_name: botName,
    });
  } else {
    placeholder = getText('memory_screen_new_fact_empty_input_user', {
      user_name: username,
    });
    hint = getText('memory_screen_new_fact_hint_user', { user_name: username });
  }

  return (
    <ModalForm.Wrapper>
      <ModalForm.Body onSubmit={handleSubmit(handleFormSubmit)}>
        <StyledFormSelect
          id="select-memory-category"
          label="Category"
          {...register('category', {
            required: true,
          })}
          defaultValue={watchCategoryId}
          onChange={(value) => {
            setValue('category', value, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });

            resetField('text');
          }}
          options={sortedMemoryCategories}
          disabled={categoryDisabled}
        />

        {FeatureFlags.statementsAboutReplika &&
          !!watchCategory &&
          !isMemoryCategoryType(watchCategory, 'replika_fact') && (
            <StyledFormSelect
              id="select-memory-about"
              label="About"
              value={factType}
              onChange={(value) => {
                setFactType(value as MemoryFactType);
              }}
              options={[
                {
                  value: MemoryFactType.RobotFacts,
                  label: botName,
                },
                {
                  value: MemoryFactType.CustomerFacts,
                  label: 'You',
                },
              ]}
            />
          )}
        <FactTextArea
          id="add-fact-text"
          maxLength={200}
          placeholder={placeholder}
          {...register('text', {
            required: true,
          })}
          defaultValue={watchText}
          onChange={(e) =>
            setValue('text', e.target.value, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            })
          }
          aria-invalid={!!errors.text}
        />
        {errors.text?.message && (
          <ModalForm.FieldError error={errors.text.message} />
        )}
        {hint && !errors.text?.message && (
          <ModalForm.FieldHint>{hint}</ModalForm.FieldHint>
        )}

        <ModalForm.Actions>
          <ModalForm.SaveEditButton type="submit" showSpinner={isSubmitting}>
            Save
          </ModalForm.SaveEditButton>

          <ModalForm.DeleteButton
            type="button"
            onClick={handleDeleteButtonClick}
            aria-label="delete fact"
            showSpinner={deleteStatus === 'submitting'}
          />
        </ModalForm.Actions>
      </ModalForm.Body>
    </ModalForm.Wrapper>
  );
}

export default function EditFactFormWrapper(props: EditFactFormWrapperProps) {
  const sortedFacts = useApi(
    (state) => state.memory.persist.sortedFacts,
    getMemory,
  );

  const fact = sortedFacts?.find((f) => f.id === props.memoryId);

  if (!fact) {
    return <StyledLoadingPage />;
  }

  return <EditFactForm fact={fact} {...props} />;
}

const StyledLoadingPage = styled(LoadingPage)`
  width: 100%;
  min-height: auto;
  background: transparent;
`;

const StyledFormSelect = styled(FormSelect)`
  margin-top: 10px;
`;

const FactTextArea = styled(ModalForm.TextArea)`
  margin-top: 10px;
`;
