import * as React from 'react';
import styled from 'styled-components/macro';

import {
  CoinsCount,
  GemConversion,
  GemPurchase,
  GemsCount,
} from '../../types/models';
import formatPrice from '../../utils/formatPrice';
import imageSourceSet from '../../utils/imageSourceSet';
import { MAIN_PAGE_URL_BASE } from '../../utils/uri';

import { ButtonWithSpinner } from '../../components/Buttons';
import { LinkProps } from '../../components/Link';
import Picture from '../../components/Picture';
import { UpperBadge, UpperBadgeText } from '../../components/UpperBadge';
import { GemIcon } from '../../components/WalletButton/WalletIcons';
import outlineCss from '../../components/outlineCss';

export type ItemGemProps = {
  type: 'Gems';
  item: GemPurchase;
};

export type ItemGemConversionProps = {
  type: 'Coins';
  item: GemConversion;
};

export type WalletItemCardProps = {
  cardInfo: ItemGemProps | ItemGemConversionProps;
  inProgress?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  standalone?: boolean;
  to?: LinkProps['to'];
};

const GEMS_IMAGES: Record<GemsCount, string> = {
  10: 'gems_10.png',
  50: 'gems_50.png',
  100: 'gems_100.png',
  250: 'gems_250.png',
  500: 'gems_500.png',
  1000: 'gems_1000.png',
};

const COINS_IMAGES: Record<CoinsCount, string> = {
  10: 'coins_10.png',
  50: 'coins_50.png',
  100: 'coins_100.png',
  500: 'coins_500.png',
};

function isGemsPurchase(
  item: GemPurchase | GemConversion,
  type: 'Gems' | 'Coins',
): item is GemPurchase {
  return type === 'Gems';
}

function WalletItemCard({
  cardInfo,
  onClick,
  to,
  inProgress,
  standalone,
}: WalletItemCardProps) {
  const { type, item } = cardInfo;
  const isGems = isGemsPurchase(item, type);

  let count: number;
  let price: number | string;
  let upper_badge: string | undefined;
  let middle_badge: string | undefined;

  if (isGems) {
    count = item.gems_count;
    price = formatPrice(item.price);
    upper_badge = item.upper_badge;
    middle_badge = item.middle_badge;
  } else {
    count = item.coins_count;
    price = formatPrice(item.gems_count, 'Coins');
  }

  return (
    <WalletItemCardRoot
      $type={type}
      $upper_badge={upper_badge}
      $standalone={standalone}
    >
      {upper_badge && (
        <UpperBadge>
          <UpperBadgeText>{upper_badge}</UpperBadgeText>
        </UpperBadge>
      )}
      <ItemIcon
        {...imageSourceSet(
          `${MAIN_PAGE_URL_BASE}/${
            isGems
              ? GEMS_IMAGES[item.gems_count]
              : COINS_IMAGES[item.coins_count]
          }`,
        )}
        width={163}
        height={140}
      />
      <ItemCount>{count}</ItemCount>
      {!onClick ? (
        <ItemCountSubline>{type.toLowerCase()}</ItemCountSubline>
      ) : (
        <ItemPriceButton
          showSpinner={inProgress}
          disabled={inProgress}
          onClick={onClick}
          to={to}
        >
          {type === 'Coins' ? <GemIcon /> : null}
          <span>{price}</span>
        </ItemPriceButton>
      )}
      {middle_badge ? <ItemSale>{middle_badge}</ItemSale> : null}
    </WalletItemCardRoot>
  );
}

export default WalletItemCard;

const WalletItemCardRoot = styled.div<{
  $type: 'Gems' | 'Coins';
  $upper_badge?: string;
  $standalone?: boolean;
}>`
  display: flex;
  font-family: ${(p) => p.theme.fonts.display};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${(p) => (p.$standalone ? '163px' : '100%')};
  border-radius: 19px;
  position: relative;
  background: ${(p) =>
    p.$type === 'Gems' ? p.theme.walletItemGemBg : p.theme.walletItemCoinBg};
`;

const ItemIcon = styled(Picture)`
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.3));
`;

const ItemCount = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
  font-family: ${(p) => p.theme.fonts.display};
`;

const ItemCountSubline = styled.div`
  margin: 0 0 15px;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.7;
`;

const ItemPriceButton = styled(ButtonWithSpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 5px;
  margin: 10px auto;
  width: calc(100% - 20px);
  height: 34px;
  background: ${(p) => p.theme.walletButtonBg};
  box-shadow:
    0px 0px 15px rgba(209, 209, 209, 0.9),
    inset 0px 0px 5px rgba(255, 255, 255, 0.4);
  border-radius: 14px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 34px;
  color: #5e5e5e;

  &:hover {
    text-decoration: none;
  }

  &:focus-visible:focus {
    ${outlineCss()}
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const ItemSale = styled.div`
  position: absolute;
  width: 54px;
  height: 54px;
  border: none;
  top: 80px;
  right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.purchaseItemMiddleBadgeBg};
  /* Safari */
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    background: ${(p) => p.theme.purchaseItemSafariMiddleBadgeBg};
  }
  box-shadow:
    inset 0px 0px 3px #000000,
    inset 0px 0px 13.5px #ffffff;
  border-radius: 92px;
  transform: rotate(15deg);
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;
  cursor: default;
  user-select: none;
`;
